<template>
  <div class="selectProducts">
    <el-tabs type="border-card">
      <el-tab-pane label="客房">
        <div class="search">
          <span>客房名称:</span>
          <el-input
            v-model="roomListData.roomName"
            placeholder="请输入客房名称"
          ></el-input>
          <span>所属酒店:</span>
          <el-select
            v-model="roomListData.hotelId"
            clearable
            @clear="onClear(1)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in hotelList"
              :key="item.cateId"
              :label="item.cateName"
              :value="item.cateId"
            >
            </el-option>
          </el-select>
          <el-button type="primary" @click="getRoomList()">查找</el-button>
        </div>
        <el-table size="mini" border :data="roomData" style="width: 100%">
          <el-table-column
            align="center"
            prop="roomId"
            label="客房id"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="roomName"
            label="客房名称"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="hotelName"
            label="所属酒店"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="strSellWay"
            label="销售方式"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="strUnsubscribe"
            label="是否退订"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="wlShow"
            label="万旅网显示"
            width="width"
          >
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row }">
              <el-button type="primary" size="mini" @click="onAdd(row, 1)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="roomListData.currentPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="roomListData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="roomPagination.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="门票">
        <div class="search">
          <span>门票名称:</span>
          <el-input
            v-model="ticketListData.ticketName"
            placeholder="请输入门票名称"
          ></el-input>
          <span>所属景区:</span>
          <el-select
            v-model="ticketListData.sceneryId"
            clearable
            @clear="onClear(2)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in sceneryList"
              :key="item.cateId"
              :label="item.cateName"
              :value="item.cateId"
            >
            </el-option>
          </el-select>
          <el-button type="primary" @click="getTicketList()">查找</el-button>
        </div>
        <el-table size="mini" border :data="ticketDate" style="width: 100%">
          <el-table-column
            align="center"
            prop="ticketId"
            label="门票ID"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="ticketName"
            label="门票名称"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="sceneryName"
            label="所属景区"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="strSellWay"
            label="销售方式"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="wlShow"
            label="万旅网显示"
            width="width"
          >
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row }">
              <el-button type="primary" size="mini" @click="onAdd(row, 2)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="ticketListData.currentPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="ticketListData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="ticketPagination.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="线路">
        <div v-if="!taocanListData.travelId">
          <div class="search line">
            <span>商品名称:</span>
            <el-input
              v-model="lineListData.travelName"
              placeholder="请输入商品名称"
            ></el-input>
            <span>所属分类:</span>
            <el-select
              clearable
              @clear="onClear(4)"
              v-model="cateValue"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateListA"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
            <el-select
              clearable
              @clear="onClear(5)"
              v-show="cateValue"
              v-model="cateValue1"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateListB"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
            <el-select
              clearable
              @clear="onClear(3)"
              v-show="cateValue1"
              v-model="lineListData.cateId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateListC"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
            <el-button type="primary" @click="getlineList()">查找</el-button>
          </div>
          <el-table size="mini" border :data="lineDate" style="width: 100%">
            <el-table-column
              align="center"
              prop="travelId"
              label="线路ID"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="travelName"
              label="副标题"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="cateName"
              label="所属分类"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="unsubscribe"
              label="是否退订"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="wlShow"
              label="万旅网显示"
              width="width"
            >
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button
                  type="primary"
                  size="mini"
                  @click="onTaocan(row.travelId)"
                  >查看套餐</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange3"
            @current-change="handleCurrentChange3"
            :current-page="lineListData.currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="lineListData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="linePagination.total"
          >
          </el-pagination>
        </div>
        <div v-if="taocanListData.travelId">
          <div class="search">
            <span>套餐名称:</span>
            <el-input
              v-model="taocanListData.specsName"
              placeholder="请输入套餐名称"
            ></el-input>
            <el-button
              type="primary"
              style="margin-left: 20px"
              @click="gettravelSpecsList()"
              >查找</el-button
            >
            <el-button type="primary" @click="taocanListData.travelId = null"
              >选择线路</el-button
            >
          </div>
          <el-table size="mini" border :data="taocanDate" style="width: 100%">
            <el-table-column
              align="center"
              prop="specsId"
              label="套餐ID"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="travelName"
              label="线路名称"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="specsName"
              label="套餐名称"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="roomCount"
              label="所需房间数量"
              width="width"
            >
            </el-table-column>
            <!-- <el-table-column align="center"
              prop="strSellWay"
              label="销售方式"
              width="width">
            </el-table-column> -->

            <el-table-column align="center" label="操作" :key="111">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd(row, 3)"
                  >确认</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange4"
            @current-change="handleCurrentChange4"
            :current-page="taocanListData.currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="taocanListData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="taocanPagination.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="美食" v-if="showFood">
        <div v-if="!packListData.foodId">
          <!-- <div class="search line">
              <span>商品名称:</span>
              <el-input
                v-model="foodListData.travelName"
                placeholder="请输入商品名称"
              ></el-input>
              <span>所属分类:</span>
              <el-select
                clearable
                @clear="onClear(4)"
                v-model="cateValue"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in cateListA"
                  :key="item.cateId"
                  :label="item.cateName"
                  :value="item.cateId"
                >
                </el-option>
              </el-select>
              <el-select
                clearable
                @clear="onClear(5)"
                v-show="cateValue"
                v-model="cateValue1"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in cateListB"
                  :key="item.cateId"
                  :label="item.cateName"
                  :value="item.cateId"
                >
                </el-option>
              </el-select>
              <el-select
                clearable
                @clear="onClear(3)"
                v-show="cateValue1"
                v-model="lineListData.cateId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in cateListC"
                  :key="item.cateId"
                  :label="item.cateName"
                  :value="item.cateId"
                >
                </el-option>
              </el-select>
              <el-button type="primary" @click="getlineList()">查找</el-button>
            </div> -->
          <el-table size="mini" border :data="foodDate" style="width: 100%">
            <el-table-column
              align="center"
              prop="foodId"
              label="美食ID"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="title"
              label="美食标题"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="cateName"
              label="所属分类"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="wlShow"
              label="万旅网显示"
              width="width"
            >
            </el-table-column>

            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button
                  type="primary"
                  size="mini"
                  @click="onTaocanFood(row.foodId)"
                  >查看套餐</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange5"
            @current-change="handleCurrentChange5"
            :current-page="foodListData.currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="foodListData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="foodPagination.total"
          >
          </el-pagination>
        </div>
        <div v-if="packListData.foodId">
          <div class="search">
            <!-- <span>套餐名称:</span>
              <el-input
                v-model="taocanListData.specsName"
                placeholder="请输入套餐名称"
              ></el-input>
              <el-button
                type="primary"
                style="margin-left: 20px"
                @click="gettravelSpecsList()"
                >查找</el-button
              > -->
            <el-button type="primary" @click="packListData.foodId = null"
              >选择美食</el-button
            >
          </div>
          <el-table size="mini" border :data="packDate" style="width: 100%">
            <el-table-column
              align="center"
              prop="packId"
              label="套餐ID"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="foodName"
              label="美食名称"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="packName"
              label="套餐名称"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="fee"
              label="价格"
              width="width"
            >
            </el-table-column>
            <!-- <el-table-column align="center"
              prop="strSellWay"
              label="销售方式"
              width="width">
            </el-table-column> -->
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd(row, 5)"
                  >确认</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange6"
            @current-change="handleCurrentChange6"
            :current-page="packListData.currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="packListData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="packPagination.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="万旅卡">
        <div>
          <div class="search line">
            <span>万旅卡名称:</span>
            <el-input
              v-model="cardList.cardName"
              placeholder="请输入分类名称"
              style="margin-right: 10px"
            ></el-input>
            <el-button type="primary" @click="getCardList()">查找</el-button>
          </div>
          <el-table
            size="mini"
            border
            :data="cateListD"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              align="center"
              prop="cardId"
              label="卡片ID"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="cateName"
              label="所属分类"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="cardName"
              label="卡片名称"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="label"
              label="标签"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="price"
              label="价格"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="strStatus"
              label="状态"
              width="width"
            >
            </el-table-column>

            <el-table-column
              align="center"
              prop="wlShow"
              label="万旅网显示"
              width="width"
            >
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd(row, 4)"
                  >确认</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleChangeMall"
            @current-change="handleChangeMall"
            :current-page="cardList.currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="cardList.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="cardList.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="精选商城">
        <div>
          <div class="search line">
            <span>名称:</span>
            <el-input
              v-model="selectMallList.prodName"
              placeholder="请输入商品名称"
              style="margin-right: 10px"
            ></el-input>
            <el-button type="primary" @click="getSeletMallList()"
              >查找</el-button
            >
          </div>
          <el-table
            size="mini"
            border
            :data="cateListF"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              align="center"
              prop="prodId"
              label="商品ID"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="prodName"
              label="商品名称"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="prodPrice"
              label="价格"
              width="width"
            >
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="prodPrice"
              label="价格"
              width="width"
            >
            </el-table-column> -->
            <el-table-column
              align="center"
              prop="prodStatus"
              label="状态"
              width="width"
            >
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd(row, 6)"
                  >确认</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleChangeMall"
            @current-change="handleChangeMall"
            :current-page="selectMallList.currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="selectMallList.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="selectMallList.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
  <script>
import { room, ticket, travel } from "../../../api/groupBuy";
import { allHotel } from "../../../api/hotelList";

import { allScenery } from "../../../api/sceneryList";

import { selectAllByFatherId } from "../../../api/travel";

import { travelSpecsList } from "../../../api/travelSpecs";
import { isActivityKill } from "../../../api/seckillInfo";
import { foodList2, foodPackList } from "../../../api/food";
import { cardListWXD } from "@/api/wanlvCard";
import { selectMallProfitList } from "@/api/selectMall";
import {
  productList,

  
} from "../../../api/selectMall";
import {
  cardList,

} from "../../../api/wanlvCard";
export default {
  name: "selectProducts",
  props: {
    showFood: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
     
      hotelList: [],
      roomData: [],
      roomListData: {
        currentPage: 1,
        pageSize: 5,
        hotelId: null,
        roomName: "",
        status: 1,
      },
      roomPagination: {},
      sceneryList: [],
      ticketDate: [],
      ticketListData: {
        currentPage: 1,
        pageSize: 5,
        ticketName: "",
        sceneryId: null,
        status: 1,
      },
      ticketPagination: {},
      lineDate: [],
      lineListData: {
        currentPage: 1,
        pageSize: 5,
        travelName: "",
        cateId: null,
        status: 1,
      },
      linePagination: {},
      cateValue: null,
      cateValue1: null,
      cateListA: [],
      cateListB: [],
      cateListC: [],
      taocanPagination: {},
      taocanDate: [],
      taocanListData: {
        currentPage: 1,
        pageSize: 5,
        specsName: "",
        travelId: null,
        status: 1,
      },
      foodDate: [],
      packDate: [],
      foodListData: {
        currentPage: 1,
        pageSize: 5,
      },
      packListData: {
        foodId: null,
        currentPage: 1,
        pageSize: 5,
      },
      foodPagination: [],
      packPagination: [],
      selectMallList: {
        pageSize: 5,
        currentPage: 1,
        prodName: "",
      },
      cateListD: [],
      cateListF: [],
      cardList: {
        pageSize: 5,
        currentPage: 1,
        cardName: "",
      },
    };
  },
  watch: {
    cateValue: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue,
      });
      this.cateListB = data.data;
    },
    cateValue1: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue1,
      });
      this.cateListC = data.data;
    },
  },
  created() {
    this.getAllHotel();
    this.getRoomList();
    this.getallScenery();
    this.getTicketList();
    this.getTravelCate();
    this.getlineList();
    this.getFoodList();
    this.getCardlist();
    this.getSeletMallList();
  },
  methods: {
    //万旅卡
    async getCardlist() {
      const { data } = await cardList(this.cardList);
      if (data.code === 0) {
        this.cateListD = data.list;
        this.pagination = data.pagination;
      } else {
        this.$message.error(data.msg);
      }
    },
    //精选商城列表
    async getSeletMallList() {
      const { data } = await productList(this.selectMallList);
      console.log(data, "精选商城列表");
      if (data.code === 0) {
        this.cateListF = data.list;
        this.pagination = data.pagination;
      } else {
        this.$message.error(data.msg);
      }
    },
    onClear(value) {
      if (value == 1) {
        this.roomListData.hotelId = null;
      } else if (value == 2) {
        this.ticketListData.sceneryId = null;
      } else if (value == 3) {
        this.lineListData.cateId = null;
      } else if (value == 4) {
        this.cateValue = null;
        this.cateValue1 = null;
        this.lineListData.cateId = null;
      } else if (value == 5) {
        this.cateValue1 = null;
        this.lineListData.cateId = null;
      }
    },
    async getAllHotel() {
      const { data } = await allHotel();
      this.hotelList = data.data;
    },
    async getRoomList() {
      const { data } = await room(this.roomListData);
      this.roomData = data.list;
      this.roomPagination = data.pagination;
    },
    async getallScenery() {
      const { data } = await allScenery();
      this.sceneryList = data.data;
    },
    async getTicketList() {
      const { data } = await ticket(this.ticketListData);
      console.log(data, "qeqw1321");
      this.ticketDate = data.list;
      this.ticketPagination = data.pagination;
    },
    async getTravelCate() {
      const { data } = await selectAllByFatherId({
        fatherId: 0,
      });
      this.cateListA = data.data;
    },
    async getlineList() {
      const { data } = await travel(this.lineListData);
      this.lineDate = data.list;
      this.linePagination = data.pagination;
    },
    async gettravelSpecsList() {
      const { data } = await travelSpecsList(this.taocanListData);
      console.log(data, "13213adaw");
      this.taocanDate = data.list;
      this.taocanPagination = data.pagination;
    },
    async getFoodList() {
      const { data } = await foodList2(this.foodListData);
      this.foodDate = data.list;
      this.foodPagination = data.pagination;
    },
    async getPackList() {
      const { data } = await foodPackList(this.packListData);
      console.log(data, "13213adaw");
      this.packDate = data.list;
      this.packPagination = data.pagination;
    },
    async onAdd(row, type) {
      if (type == 1) {
        var obj = {
          id: row.roomId,
          name: row.roomName,
        };
        const { data } = await isActivityKill({
          prodType: type,
          prodId: row.roomId,
        });
        if (data.code == 0) {
          this.$emit("addData", obj, type);
          return this.$message.success(data.msg);
        } else {
          return this.$message.error(data.msg);
        }
      } else if (type == 2) {
        var obj = {
          id: row.ticketId,
          name: row.ticketName,
        };
        const { data } = await isActivityKill({
          prodType: type,
          prodId: row.ticketId,
        });
        if (data.code == 0) {
          this.$emit("addData", obj, type);
          return this.$message.success(data.msg);
        } else {
          return this.$message.error(data.msg);
        }
      } else if (type == 3) {
        var obj = {
          id: row.specsId,
          name: row.specsName,
        };
        const { data } = await isActivityKill({
          prodType: type,
          prodId: row.specsId,
        });
        if (data.code == 0) {
          this.$emit("addData", obj, type);
          return this.$message.success(data.msg);
        } else {
          return this.$message.error(data.msg);
        }
      } else if (type == 5) {
        var obj = {
          id: row.packId,
          name: row.packName,
        };
        const { data } = await isActivityKill({
          prodType: 4,
          prodId: row.packId,
        });
        if (data.code == 0) {
          this.$emit("addData", obj, type);
          return this.$message.success(data.msg);
        } else {
          return this.$message.error(data.msg);
        }
      }else if (type == 4) {
        var obj = {
          id: row.cardId,
          name: row.cardName,
        };
        const { data } = await isActivityKill({
          prodType: type,
          prodId: row.cardId,
        });
        if (data.code == 0) {
          this.$emit("addData", obj, type);
          return this.$message.success(data.msg);
        } else {
          return this.$message.error(data.msg);
        }
      }else if (type == 6) {
        var obj = {
          id: row.prodId,
          name: row.prodName,
        };
        const { data } = await isActivityKill({
          prodType: type,
          prodId: row.prodId,
        });
        if (data.code == 0) {
          this.$emit("addData", obj, type);
          return this.$message.success(data.msg);
        } else {
          return this.$message.error(data.msg);
        }
      }


    },
    onTaocan(id) {
      this.taocanListData.travelId = id;
      this.gettravelSpecsList();
    },
    handleChangeCard(num) {
      this.cardList.pageSize = num;
      this.getCardlist();
    },
    handleCurrentCard(num) {
      this.cardList.pageSize = num;
      this.getCardlist();
    },
    onTaocanFood(id) {
      this.packListData.foodId = id;
      this.getPackList();
    },
    handleSizeChange1(num) {
      this.roomListData.pageSize = num;
      this.getRoomList();
    },
    handleCurrentChange1(num) {
      this.roomListData.currentPage = num;
      this.getRoomList();
    },
    handleSizeChange2(num) {
      this.ticketListData.pageSize = num;
      this.getTicketList();
    },
    handleCurrentChange2(num) {
      this.ticketListData.currentPage = num;
      this.getTicketList();
    },
    handleSizeChange3(num) {
      this.lineListData.pageSize = num;
      this.getlineList();
    },
    handleCurrentChange3(num) {
      this.lineListData.currentPage = num;
      this.getlineList();
    },
    handleSizeChange4(num) {
      this.taocanListData.pageSize = num;
      this.gettravelSpecsList();
    },
    handleCurrentChange4(num) {
      this.taocanListData.currentPage = num;
      this.gettravelSpecsList();
    },
    handleSizeChange5(num) {
      this.foodListData.pageSize = num;
      this.getFoodList();
    },
    handleCurrentChange5(num) {
      this.foodListData.currentPage = num;
      this.getFoodList();
    },
    handleSizeChange6(num) {
      this.packListData.pageSize = num;
      this.getPackList();
    },
    handleCurrentChange6(num) {
      this.packListData.currentPage = num;
      this.getPackList();
    },
  },
};
</script>
  <style lang="less" scoped>
.selectProducts {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    span {
      margin: 0 20px;
    }
    .el-input {
      width: 200px;
    }
    .el-select {
      margin: 0 20px;
    }
  }
  .line {
    .el-select {
      width: 150px;
    }
  }
  .el-table {
    margin-top: 20px;
  }
  .el-pagination {
    margin-top: 20px;
    float: right;
  }
}
</style>