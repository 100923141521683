<template>
  <div class="addRoom">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加礼券</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 表单 -->
    <el-form
      label-width="110px"
      ref="addFromRef"
      :model="formList"
      :rules="addFromRules"
    >
      <el-form-item label="礼券名称" prop="packProdName">
        <el-input
          v-model="formList.packProdName"
          placeholder="请输入礼券名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="产品">
        <el-button type="primary" @click="dialogVisible = true"
          >选择产品</el-button
        >
      </el-form-item>
     
      <el-form-item label="" v-if="formList.prodType">
        <el-table :data="tableData" border style="width:100%">
          <el-table-column prop="name" label="产品名称" >
            <template slot-scope="{ row }">
              <div>
                {{ row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="原价" >
            <template slot-scope="{ row }">
              <div>
                <el-input
                  v-model="row.oldPrice"
                  style="width: 200px; margin-right: 10px"
                  type="number"
                  placeholder="请输入原价"
                ></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="售价" >
            <template slot-scope="{ row }">
              <div>
                <el-input
                  v-model="row.price"
                  style="width: 200px; margin-right: 10px"
                  type="number"
                  placeholder="请输入售价"
                ></el-input>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
       <div style="display:flex">
        <div>
          说明：
        </div>
        <div>
          <span> 1、为每张的升级补差价格=升级价格-售价</span>
          <br/>
          <span> 2、如设置产品核销一张得一张佣金，则售价为佣金结算价，店长佣金=(每张售价+补差)x佣金比例</span>
        </div>
       </div>
         
      </el-form-item>

      <!-- <el-form-item label="商品类型:" prop="prodType">
      
        <el-select
          v-model="formList.prodType"
          @change="getProdInfo"
          placeholder="请选择"
        >
          <el-option
            v-for="item in goodsTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品id" v-if="formList.prodType" prop="prodId">
        <el-input
          v-model="formList.prodId"
          placeholder="请输入商品id"
          @blur="getProdInfo"
        ></el-input>
      </el-form-item> -->
      <!-- <el-form-item
        label="已选:"
        prop="prodId"
        v-if="formList.prodType && selectMsg"
      >
        <span>{{ selectMsg }}</span>
      </el-form-item> -->
      <el-form-item
        label="入住天数"
        prop="roomDay"
        v-if="formList.prodType == 1"
      >
        <el-input
          v-model="formList.roomDay"
          style="width: 300px; margin-right: 10px"
          min="2"
          type="number"
          placeholder="请输入入住天数"
          @input="onInputChange"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="售价" prop="prodFee">
        <el-input
          v-model="formList.prodFee"
          style="width: 300px; margin-right: 10px"
          min="2"
          type="number"
          placeholder="请输入售价"
          @input="onInputChange"
        ></el-input>
      </el-form-item>
      <el-form-item label="结算价" prop="prodEndFee">
        <el-input
          v-model="formList.prodEndFee"
          style="width: 300px; margin-right: 10px"
          min="2"
          type="number"
          placeholder="请输入结算价"
          @input="onInputChange"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="展示图" prop="showImg">
        <send-image
          type="one"
          :images="formList.showImg"
          @addImageStr="(e) => addImageStr(e, 1)"
        ></send-image>
      </el-form-item>
      <el-form-item label="数量" prop="count">
        <el-input
          v-model="formList.count"
          style="width: 300px; margin-right: 10px"
          min="2"
          type="number"
          placeholder="请输入数量"
          @input="onInputChange"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="onSave">保存</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      title="选择产品"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <selectProductsPack @addData="onAddData"></selectProductsPack>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
    <script>
import { addPackProd, getPackProdDetail } from "../../../api/seckillInfo";
import { prizeProd } from "../../../api/invite.js";
import SendImage from "../../../components/sendImage.vue";
import selectProductsPack from "./selectProductsPack.vue";
export default {
  name: "addActivityLinking",
  components: {
    SendImage,
    selectProductsPack,
  },
  data() {
    return {
      dialogVisible: false,
      radio: "1",
      roomImg: [],
      tableData: [
        {
          name: "",
          oldPrice: "",
          price: "",
        },
      ],
      formList: {
        packProdName: "",
        packProdId: "",
        packId: "",
        prodId: "",
        prodType: "",
        prodFee: "",
        prodEndFee: "",
        showImg: "",
        count: "",
        roomDay: 1,
      },
      goodsTypeList: [
        {
          label: "客房",
          value: 1,
        },
        {
          label: "门票",
          value: 2,
        },
        {
          label: "线路套餐",
          value: 3,
        },
        {
          label: "万旅卡",
          value: 4,
        },
        {
          label: "美食",
          value: 5,
        },
        {
          label: "精选商城",
          value: 6,
        },
      ],
      selectMsg: "",
      isSubmit: false,
      integral: null,
      addFromRules: {
        packProdName: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        prodType: [{ required: true, message: "请输入内容", trigger: "blur" }],
        prodId: [{ required: true, message: "请输入内容", trigger: "blur" }],
        prodFee: [{ required: true, message: "请输入内容", trigger: "blur" }],
        prodEndFee: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        showImg: [{ required: true, message: "请输入内容", trigger: "blur" }],
        count: [{ required: true, message: "请输入内容", trigger: "blur" }],
        roomDay: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
    };
  },
  created() {
    //   this.type = this.$route.query.type;
    //   if (this.$route.query.connectId) {
    //     this.formList.connectId = this.$route.query.connectId;
    //     this.getActivedetail();
    //   } else if (this.$route.query.advanceId) {
    //     this.formList.advanceId = this.$route.query.advanceId;
    //     this.getActivedetail();
    //   }
    this.formList.packId = this.$route.query.packId || 0;
    this.formList.packProdId = this.$route.query.packProdId || 0;
    console.log(this.formList.packProdId);
    if (this.formList.packProdId > 0) {
      this.getDetails();
    }
  },
  methods: {
    onAddData(obj, type) {
      this.formList.prodType = type;
      console.log(this.tableData, obj);
      this.tableData = [
        {
          price: this.tableData[0].price,
          oldPrice: this.tableData[0].oldPrice,

          name: obj.name,
        },
      ];

      this.formList.prodId = obj.id;
      this.dialogVisible = false;
    },
    onInputChange(value) {
      if (isNaN(value)) {
        this.$message({
          showClose: true,
          message: "请输入数字！",
          type: "warning",
        });
      }
    },
    getProdInfo() {
      if (this.formList.prodId && this.formList.prodType) {
        let prodType = "";
        switch (this.formList.prodType) {
          case 1:
            prodType = 4;
            break;
          case 2:
            prodType = 3;
            break;
          case 3:
            prodType = 9;
            break;
          case 4:
            prodType = 5;
            break;
          case 5:
            prodType = 7;
            break;
          case 6:
            prodType = 8;
            break;
        }
        prizeProd({
          prodId: this.formList.prodId,
          prodType,
        }).then((res) => {
          if (res.data.code == 0) {
            console.log(res.data.msg);
            this.selectMsg = res.data.msg;
          } else {
            this.$message.warning(res.data.msg);
            this.selectMsg = "";
            this.formList.prodId = "";
          }
        });
      }
    },
    //上传图片
    addImageStr(image) {
      this.formList.showImg = image;
    },
    onSave() {
      this.tableData.forEach((item) => {
        if (item.oldPrice == "" || item.price == "") {
          this.$message.warning("请填写完整的产品信息");
          return;
        } else {
          this.formList.prodFee = item.oldPrice;
          this.formList.prodEndFee = item.price;
        }
      });
      console.log(this.formList);
      // return
      this.$refs.addFromRef.validate(async (validate) => {
        if (!validate) return;
        if (this.isSubmit) return;
        this.isSubmit = true;
        if (this.formList.prodType != 1) {
          this.formList.roomDay = 0;
        }
        this.formList.packProdId = this.$route.query.packProdId || 0;
        const { data } = await addPackProd(this.formList);
        this.isSubmit = false;
        if (data.code == 0) {
          this.$message.success(data.msg);
          //   this.getDetails();
          this.$router.go(-1);
        } else {
          this.$message.warning(data.msg);
        }
      });
    },
    getDetails() {
      getPackProdDetail({
        prodPackId: this.formList.packProdId,
      }).then((res) => {
        if (res.data.code == 0) {
          Object.keys(this.formList).forEach((key) => {
            this.formList[key] = res.data.data[key];
          });
          this.tableData = [
            {
              name: res.data.data.prodName,
              oldPrice: res.data.data.prodFee,
              price: res.data.data.prodEndFee,
            },
          ];
          this.getProdInfo();
        }
      });
    },
  },
};
</script>
    <style lang="less" scoped>
.addRoom {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/.chanpin {
    .el-form-item__content {
      .el-table {
        .el-table__body-wrapper {
          tbody {
            td {
              .el-input {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
  /deep/ .el-form {
    width: 800px;
    margin-top: 50px;
    .col,
    .col1 {
      display: inline-block;
      .el-form-item__content {
        width: 200px;
      }
    }
    .el-form-item__content {
      width: 800px;
    }
    .showImg {
      .el-form-item__content {
        display: flex;
        flex-wrap: wrap;
        .img {
          position: relative;
          height: 100px;
          width: 100px;
          margin: 20px 20px;
          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 2px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          margin-top: 20px;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
      }
    }
  }
  /deep/.el-form-item {
    margin-top: 50px;
    .el-form-item__label {
      width: 120px !important;
    }
    .el-form-item__content {
      margin-left: 120px !important;
      display: flex;
      .img {
        position: relative;
        height: 100px;
        width: 100px;
        margin: 20px 20px;
        img {
          height: 100px;
          width: 100px;
          border: 1px solid #c0ccda;
        }
        div {
          position: absolute;
          top: 0;
          right: 2px;
          height: 10px;
          width: 10px;
          z-index: 100;
        }
      }
      .addImg {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px dashed #c0ccda;
        text-align: center;
        line-height: 100px;
        cursor: pointer;
        margin-top: 20px;
        i {
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #c0ccda;
        }
      }
      .el-input {
        width: 600px;
      }
      .el-date-editor {
        width: 292px !important;
      }
    }
  }
  .btn {
    width: 200px;
  }
}
</style>
    