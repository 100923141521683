import request from '../utils/request'
import baseUrl from './baseUrl'

export const travelSpecsList = (data) => request({
  url: baseUrl + '/travelSpecs/selectForBack',
  method: 'POST',
  data
})
export const addTravelSpec = (data) => request({
  url: baseUrl + '/travelSpecs/add',
  method: 'POST',
  data
})
export const editTravelSpec = (data) => request({
  url: baseUrl + '/travelSpecs/add',
  method: 'POST',
  data
})
//查看详情
export const travelSpecDetails = (data) => request({
  url: baseUrl + '/travelSpecs/selectForId',
  method: 'POST',
  data
})
//上下架删除
export const changeStatus = (data) => request({
  url: baseUrl + '/travelSpecs/modifyStatus',
  method: 'POST',
  data
})
//上下架删除
export const changeSort = (data) => request({
  url: baseUrl + '/travelSpecs/modifyDept',
  method: 'POST',
  data
})
//套餐日志
export const travelSpecsLog = (data) => request({
  url: baseUrl + '/travelSpecsLog/selectForBack',
  method: 'POST',
  data
})
// 日志详情
export const travelSpecsLogDetails = (data) => request({
  url: baseUrl + '/travelSpecsLog/selectForId',
  method: 'POST',
  data
})
//一键复制
export const addByCody = (data) => request({
  url: baseUrl + '/travelSpecs/addCopy',
  method: 'POST',
  data
})