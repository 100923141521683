import request from '../utils/request'
import baseUrl from './baseUrl'


export const inviteSetInfo = (data) => request({
  url: baseUrl + '/inviteOrderSet/selectForId',
  method: 'POST',
  data
})
export const inviteSet = (data) => request({
  url: baseUrl + '/inviteOrderSet/modify',
  method: 'POST',
  data
})
export const addInvite = (data) => request({
  url: baseUrl + '/inviteOrderSet/add',
  method: 'POST',
  data
})
export const prize = (data) => request({
  url: baseUrl + '/orderGoal/selectForBack',
  method: 'POST',
  data
})
export const prizeAdd = (data) => request({
  url: baseUrl + '/orderGoal/add',
  method: 'POST',
  data
})
export const prizeDetails = (data) => request({
  url: baseUrl + '/orderGoal/selectForId',
  method: 'POST',
  data
})
export const prizeDel = (data) => request({
  url: baseUrl + '/orderGoal/deleteById',
  method: 'POST',
  data
})
export const prizeProd = (data) => request({
  url: baseUrl + '/orderGoal/selectProdName',
  method: 'POST',
  data
})
// 活动设置列表
export const getInviteOrderSetList = (data) => request({
  url: baseUrl + '/inviteOrderSet/selectForBack',
  method: 'POST',
  data
})
export const updateInviteOrderSetStatus = (data) => request({
  url: baseUrl + '/inviteOrderSet/updateStatus',
  method: 'POST',
  data
})
// 奖励列表
export const getorderPrizeList = (data) => request({
  url: baseUrl + '/orderPrize/selectForBack',
  method: 'POST',
  data
})
export const updateorderPrizeSetStatus = (data) => request({
  url: baseUrl + '/orderPrize/modify',
  method: 'POST',
  data
})
export const updateEndTime = (data) => request({
  url: baseUrl + '/orderPrize/updateEndTime',
  method: 'POST',
  data
})
export const updateUseEndTime = (data) => request({
  url: baseUrl + '/orderPrize/updateUseEndTime',
  method: 'POST',
  data
})
export const exportOrderPrizeList = (data) => request({
  url: baseUrl + '/orderPrize/selectForExport',
  method: 'POST',
  data,
  responseType: "blob",
})
// 邀请订单
export const getInviteOrderList = (data) => request({
  url: baseUrl + '/order/selectForInvite',
  method: 'POST',
  data
})
export const exportInviteOrderList = (data) => request({
  url: baseUrl + '/order/selectForInviteExport',
  method: 'POST',
  data,
  responseType: "blob",
})


// 邀请注册
// 奖品列表
export const prizeRegister= (data) => request({
  url: baseUrl + '/userGoal/selectForBack',
  method: 'POST',
  data
})
export const prizeAddRegister = (data) => request({
  url: baseUrl + '/userGoal/add',
  method: 'POST',
  data
})
export const prizeDetailsRegister = (data) => request({
  url: baseUrl + '/userGoal/selectForId',
  method: 'POST',
  data
})
export const prizeDelRegister = (data) => request({
  url: baseUrl + '/userGoal/deleteById',
  method: 'POST',
  data
})
// 活动设置
export const getInviteOrderSetListRegister = (data) => request({
  url: baseUrl + '/inviteUserSet/selectForBack',
  method: 'POST',
  data
})
export const updateInviteOrderSetStatusRegister = (data) => request({
  url: baseUrl + '/inviteUserSet/updateStatus',
  method: 'POST',
  data
})
export const inviteSetInfoRegister = (data) => request({
  url: baseUrl + '/inviteUserSet/selectForId',
  method: 'POST',
  data
})
export const inviteSetRegister = (data) => request({
  url: baseUrl + '/inviteUserSet/modify',
  method: 'POST',
  data
})
export const addInviteRegister = (data) => request({
  url: baseUrl + '/inviteUserSet/add',
  method: 'POST',
  data
})
// 奖励列表
export const getorderPrizeListRegister = (data) => request({
  url: baseUrl + '/userPrize/selectForBack',
  method: 'POST',
  data
})
export const updateorderPrizeSetStatusRegister = (data) => request({
  url: baseUrl + '/userPrize/modify',
  method: 'POST',
  data
})
export const updateEndTimeRegister = (data) => request({
  url: baseUrl + '/userPrize/updateEndTime',
  method: 'POST',
  data
})
export const updateUseEndTimeRegister = (data) => request({
  url: baseUrl + '/userPrize/updateUseEndTime',
  method: 'POST',
  data
})
export const exportOrderPrizeListRegister = (data) => request({
  url: baseUrl + '/userPrize/selectForExport',
  method: 'POST',
  data,
  responseType: "blob",
})
// 邀请注册列表
export const getInviteOrderListRegister = (data) => request({
  url: baseUrl + '/userInfo/selectForInvite',
  method: 'POST',
  data
})
export const exportInviteOrderListRegister = (data) => request({
  url: baseUrl + '/userInfo/selectForInviteExport',
  method: 'POST',
  data,
  responseType: "blob",
})

// 消费得奖励
// 活动设置
export const getInviteOrderSetListConsumption = (data) => request({
  url: baseUrl + '/inviteFeeSet/selectForBack',
  method: 'POST',
  data
})
export const updateInviteOrderSetStatusConsumption = (data) => request({
  url: baseUrl + '/inviteFeeSet/updateStatus',
  method: 'POST',
  data
})
export const inviteSetInfoConsumption = (data) => request({
  url: baseUrl + '/inviteFeeSet/selectForId',
  method: 'POST',
  data
})
export const inviteSetConsumption = (data) => request({
  url: baseUrl + '/inviteFeeSet/modify',
  method: 'POST',
  data
})
export const addInviteConsumption = (data) => request({
  url: baseUrl + '/inviteFeeSet/add',
  method: 'POST',
  data
})
// 奖品列表
export const prizeConsumption= (data) => request({
  url: baseUrl + '/feeGoal/selectForBack',
  method: 'POST',
  data
})
export const prizeAddConsumption = (data) => request({
  url: baseUrl + '/feeGoal/add',
  method: 'POST',
  data
})
export const prizeDetailsConsumption = (data) => request({
  url: baseUrl + '/feeGoal/selectForId',
  method: 'POST',
  data
})
export const prizeDelConsumption = (data) => request({
  url: baseUrl + '/feeGoal/deleteById',
  method: 'POST',
  data
})
// 奖励列表
export const getorderPrizeListConsumption = (data) => request({
  url: baseUrl + '/feePrize/selectForBack',
  method: 'POST',
  data
})
export const updateorderPrizeSetStatusConsumption = (data) => request({
  url: baseUrl + '/feePrize/modify',
  method: 'POST',
  data
})
export const updateEndTimeConsumption = (data) => request({
  url: baseUrl + '/feePrize/updateEndTime',
  method: 'POST',
  data
})
export const updateUseEndTimeConsumption = (data) => request({
  url: baseUrl + '/feePrize/updateUseEndTime',
  method: 'POST',
  data
})
export const exportOrderPrizeListConsumption = (data) => request({
  url: baseUrl + '/feePrize/selectForExport',
  method: 'POST',
  data,
  responseType: "blob",
})
// 订单列表
export const getInviteOrderListConsumption = (data) => request({
  url: baseUrl + '/order/selectForInviteFee',
  method: 'POST',
  data
})
export const exportInviteOrderListConsumption = (data) => request({
  url: baseUrl + '/order/selectForInviteExportFee',
  method: 'POST',
  data,
  responseType: "blob",
})


// 邀请/消费设置
// 查询
export const selectInviteBaseSet = (data) => request({
  url: baseUrl + '/inviteBaseSet/select',
  method: 'POST',
  data
})
// 修改
export const modifyInviteBaseSet = (data) => request({
  url: baseUrl + '/inviteBaseSet/modify',
  method: 'POST',
  data
})

// 万小店-分销等级-奖励列表
export const getorderPrizeListUpgrade= (data) => request({
  url: baseUrl + '/levelPrize/selectForBack',
  method: 'POST',
  data
})
export const updateorderPrizeSetStatusUpgrade = (data) => request({
  url: baseUrl + '/levelPrize/modify',
  method: 'POST',
  data
})
export const updateEndTimeUpgrade = (data) => request({
  url: baseUrl + '/levelPrize/updateEndTime',
  method: 'POST',
  data
})
export const updateUseEndTimeUpgraden = (data) => request({
  url: baseUrl + '/levelPrize/updateUseEndTime',
  method: 'POST',
  data
})
export const exportOrderPrizeListUpgrade = (data) => request({
  url: baseUrl + '/levelPrize/selectForExport',
  method: 'POST',
  data,
  responseType: "blob",
})