import request from '../utils/request'
import baseUrl from './baseUrl'

export const category = data =>
	request({
		url: baseUrl + '/cardCate/selectForBack',
		method: 'POST',
		data
	})
export const addOrEditCategory = data =>
	request({
		url: baseUrl + '/cardCate/add',
		method: 'POST',
		data
	})
export const changeSort = data =>
	request({
		url: baseUrl + '/cardCate/modifyDept',
		method: 'POST',
		data
	})
export const changeStatus = data =>
	request({
		url: baseUrl + '/cardCate/modify',
		method: 'POST',
		data
	})
export const showAll = data =>
	request({
		url: baseUrl + '/cardCate/selectAll',
		method: 'POST',
		data
	})
export const cardList = data =>
	request({
		url: baseUrl + '/card/selectForBack',
		method: 'POST',
		data
	})
export const cardListWXD = data =>
	request({
		url: baseUrl + '/card/selectForProfit',
		method: 'POST',
		data
	})
export const addOrEditCard = data =>
	request({
		url: baseUrl + '/card/add',
		method: 'POST',
		data
	})
export const cardDetail = data =>
	request({
		url: baseUrl + '/card/selectForId',
		method: 'POST',
		data
	})
export const changeCardSort = data =>
	request({
		url: baseUrl + '/card/modifyDept',
		method: 'POST',
		data
	})
export const changeCardStatus = data =>
	request({
		url: baseUrl + '/card/modify',
		method: 'POST',
		data
	})
export const createEr = data =>
	request({
		url: baseUrl + '/card/shareCard',
		method: 'POST',
		data
	})
export const wanlvCardShow = data =>
	request({
		url: baseUrl + '/card/modifyWlShow',
		method: 'POST',
		data
	})
